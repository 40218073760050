import { render, staticRenderFns } from "./current.vue?vue&type=template&id=320df049"
import script from "./current.vue?vue&type=script&lang=js"
export * from "./current.vue?vue&type=script&lang=js"
import style0 from "./current.vue?vue&type=style&index=0&id=320df049&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports